<template>
  <b-row>
    <b-col md="4" sm="12">
      VPN page
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ProfileVpn',
  components: { },
  data () {
    return {
    }
  }
}
</script>
